import type { SVGProps } from 'react';
import * as React from 'react';
export const BookCoverFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66634 2.5C4.82539 2.5 3.33301 3.99238 3.33301 5.83333V15C3.33301 16.3807 4.4523 17.5 5.83301 17.5H15.833C16.2932 17.5 16.6663 17.1269 16.6663 16.6667C16.6663 16.2064 16.2932 15.8333 15.833 15.8333V13.9437C16.3312 13.6555 16.6663 13.1169 16.6663 12.5V4.16667C16.6663 3.24619 15.9201 2.5 14.9997 2.5H6.66634ZM14.1663 14.1667H6.24967C5.78944 14.1667 5.41634 14.5398 5.41634 15C5.41634 15.4602 5.78944 15.8333 6.24967 15.8333H14.1663V14.1667ZM6.99967 5.83333C6.58546 5.83333 6.24967 6.16912 6.24967 6.58333C6.24967 6.99755 6.58546 7.33333 6.99967 7.33333H12.1663C12.5806 7.33333 12.9163 6.99755 12.9163 6.58333C12.9163 6.16912 12.5806 5.83333 12.1663 5.83333H6.99967ZM6.24967 9.08333C6.24967 8.66912 6.58546 8.33333 6.99967 8.33333H9.66634C10.0806 8.33333 10.4163 8.66912 10.4163 9.08333C10.4163 9.49755 10.0806 9.83333 9.66634 9.83333H6.99967C6.58546 9.83333 6.24967 9.49755 6.24967 9.08333Z"
      fill={props.fill ? props.fill : 'currentColor'}
    />
  </svg>
);
