import ZepIcon from '../ZepIcon';

import { ActionColor } from './ActionColor';
import { AddSmallCircleColor } from './AddSmallCircleColor';
import { AddSmallRectangle } from './AddSmallRectangle';
import { AddSmallRectangleFill } from './AddSmallRectangleFill';
import { AddSmallSlashRectangle } from './AddSmallSlashRectangle';
import { AddSmallSlashRectangleFill } from './AddSmallSlashRectangleFill';
import { ArrowDown } from './ArrowDown';
import { ArrowDownSmallCircleFill } from './ArrowDownSmallCircleFill';
import { ArrowLeft } from './ArrowLeft';
import { ArrowLeftSmallCircleFill } from './ArrowLeftSmallCircleFill';
import { ArrowRight } from './ArrowRight';
import { ArrowRightSmallCircleFill } from './ArrowRightSmallCircleFill';
import { ArrowsLeftright } from './ArrowsLeftright';
import { ArrowsLeftrightSquareFill } from './ArrowsLeftrightSquareFill';
import { ArrowsUpdown } from './ArrowsUpdown';
import { ArrowsUpdownleftright } from './ArrowsUpdownleftright';
import { ArrowTurnDownleft } from './ArrowTurnDownleft';
import { ArrowTurnDownright } from './ArrowTurnDownright';
import { ArrowTurnLeftdown } from './ArrowTurnLeftdown';
import { ArrowTurnLeftup } from './ArrowTurnLeftup';
import { ArrowTurnRightdown } from './ArrowTurnRightdown';
import { ArrowTurnRightup } from './ArrowTurnRightup';
import { ArrowTurnUpleft } from './ArrowTurnUpleft';
import { ArrowTurnUpright } from './ArrowTurnUpright';
import { ArrowUp } from './ArrowUp';
import { ArrowUpSmallCircleFill } from './ArrowUpSmallCircleFill';
import { Authority1Color } from './Authority1Color';
import { Authority2Color } from './Authority2Color';
import { Authority3Color } from './Authority3Color';
import { BatteryFill } from './BatteryFill';
import { Bell } from './Bell';
import { BellFill } from './BellFill';
import { BellSlash } from './BellSlash';
import { BellSlashFill } from './BellSlashFill';
import { Bolt } from './Bolt';
import { BoltFill } from './BoltFill';
import { Book } from './Book';
import { BookCoverFill } from './BookCoverFill';
import { BookFill } from './BookFill';
import { Bulb } from './Bulb';
import { BulbColor } from './BulbColor';
import { Calendar } from './Calendar';
import { CalendarFill } from './CalendarFill';
import { Camera } from './Camera';
import { CameraFill } from './CameraFill';
import { CaretBigDown } from './CaretBigDown';
import { CaretBigLeft } from './CaretBigLeft';
import { CaretBigRight } from './CaretBigRight';
import { CaretBigUp } from './CaretBigUp';
import { CaretBigUpdown } from './CaretBigUpdown';
import { CaretDown } from './CaretDown';
import { CaretLeft } from './CaretLeft';
import { CaretRight } from './CaretRight';
import { CaretUp } from './CaretUp';
import { CaretUpdown } from './CaretUpdown';
import { Cart } from './Cart';
import { CartFill } from './CartFill';
import { Change } from './Change';
import { Check } from './Check';
import { CheckFlower } from './CheckFlower';
import { CheckFlowerFill } from './CheckFlowerFill';
import { CheckSmall } from './CheckSmall';
import { CheckSmallCircle } from './CheckSmallCircle';
import { CheckSmallCircleColor } from './CheckSmallCircleColor';
import { CheckSmallCircleFill } from './CheckSmallCircleFill';
import { ChevronDoubleDown } from './ChevronDoubleDown';
import { ChevronDoubleLeft } from './ChevronDoubleLeft';
import { ChevronDoubleRight } from './ChevronDoubleRight';
import { ChevronDoubleUp } from './ChevronDoubleUp';
import { ChevronDown } from './ChevronDown';
import { ChevronLeft } from './ChevronLeft';
import { ChevronRight } from './ChevronRight';
import { ChevronUp } from './ChevronUp';
import { Clipboard } from './Clipboard';
import { ClipboardCheck } from './ClipboardCheck';
import { ClipboardCheckFill } from './ClipboardCheckFill';
import { ClipboardFill } from './ClipboardFill';
import { Clock } from './Clock';
import { ClockFill } from './ClockFill';
import { Cloud } from './Cloud';
import { CloudFill } from './CloudFill';
import { CloudSlash } from './CloudSlash';
import { CloudSlashFill } from './CloudSlashFill';
import { CodeQr } from './CodeQr';
import { CoinColorV2 } from './CoinColorV2';
import { CoinStack } from './CoinStack';
import { CoinStackFill } from './CoinStackFill';
import { Contract } from './Contract';
import { CreditCard } from './CreditCard';
import { Crown } from './Crown';
import { CrownFill } from './CrownFill';
import { CurrencyDollarCircle } from './CurrencyDollarCircle';
import { CurrencyDollarCircleFill } from './CurrencyDollarCircleFill';
import { CurrencyWonCircle } from './CurrencyWonCircle';
import { CurrencyWonCircleFill } from './CurrencyWonCircleFill';
import { CurrencyYenCircle } from './CurrencyYenCircle';
import { CurrencyYenCircleFill } from './CurrencyYenCircleFill';
import { Dice } from './Dice';
import { DiceFill } from './DiceFill';
import { Download } from './Download';
import { Enter } from './Enter';
import { ExclamationmarkSmall } from './ExclamationmarkSmall';
import { ExclamationmarkSmallCircle } from './ExclamationmarkSmallCircle';
import { ExclamationmarkSmallCircleColor } from './ExclamationmarkSmallCircleColor';
import { ExclamationmarkSmallCircleFill } from './ExclamationmarkSmallCircleFill';
import { ExclamationmarkSmallTriangle } from './ExclamationmarkSmallTriangle';
import { ExclamationmarkSmallTriangleFill } from './ExclamationmarkSmallTriangleFill';
import { Expand } from './Expand';
import { Export } from './Export';
import { Eye } from './Eye';
import { EyeFill } from './EyeFill';
import { EyeSlash } from './EyeSlash';
import { EyeSlashFill } from './EyeSlashFill';
import { Face } from './Face';
import { FaceFill } from './FaceFill';
import { Filter } from './Filter';
import { Folder } from './Folder';
import { FolderCheck } from './FolderCheck';
import { FolderCheckFill } from './FolderCheckFill';
import { FolderFill } from './FolderFill';
import { Fullscreen } from './Fullscreen';
import { Funnel } from './Funnel';
import { FunnelFill } from './FunnelFill';
import { Game } from './Game';
import { GameFill } from './GameFill';
import { Gear } from './Gear';
import { GearFill } from './GearFill';
import { Globe } from './Globe';
import { GlobeFill } from './GlobeFill';
import { GoogleClassroomLogo } from './GoogleClassroomIcon';
import { GridNone } from './GridNone';
import { Hammer } from './Hammer';
import { HammerFill } from './HammerFill';
import { Heart } from './Heart';
import { HeartFill } from './HeartFill';
import { House } from './House';
import { HouseFill } from './HouseFill';
import { Import } from './Import';
import { InformationmarkSmall } from './InformationmarkSmall';
import { InformationmarkSmallCircle } from './InformationmarkSmallCircle';
import { InformationmarkSmallCircleFill } from './InformationmarkSmallCircleFill';
import { Internetconnection } from './Internetconnection';
import { InternetconnectionSlash } from './InternetconnectionSlash';
import { KeyboardDownColor } from './KeyboardDownColor';
import { KeyboardLeftColor } from './KeyboardLeftColor';
import { KeyboardRightColor } from './KeyboardRightColor';
import { KeyboardUpColor } from './KeyboardUpColor';
import { Leave } from './Leave';
import { Link } from './Link';
import { LinkDisconnect } from './LinkDisconnect';
import { List } from './List';
import { Location } from './Location';
import { LocationFill } from './LocationFill';
import { Lock } from './Lock';
import { LockFill } from './LockFill';
import { Login } from './Login';
import { Logout } from './Logout';
import { Loudspeaker } from './Loudspeaker';
import { LoudspeakerFill } from './LoudspeakerFill';
import { Magnifingglass } from './Magnifingglass';
import { MagnifingglassMinus } from './MagnifingglassMinus';
import { MagnifingglassPlus } from './MagnifingglassPlus';
import { Mail } from './Mail';
import { MailFill } from './MailFill';
import { Map } from './Map';
import { MapFill } from './MapFill';
import { Menu } from './Menu';
import { MenuSmall } from './MenuSmall';
import { MessageDots } from './MessageDots';
import { MessageDotsFill } from './MessageDotsFill';
import { MessageDotsSlash } from './MessageDotsSlash';
import { MessageDotsSlashFill } from './MessageDotsSlashFill';
import { MessageLines } from './MessageLines';
import { MessageLinesFill } from './MessageLinesFill';
import { Minimizescreen } from './Minimizescreen';
import { Minus } from './Minus';
import { MinusSmall } from './MinusSmall';
import { Mobile } from './Mobile';
import { MobileFill } from './MobileFill';
import { Music } from './Music';
import { MusicFill } from './MusicFill';
import { OptionHorizontal } from './OptionHorizontal';
import { OptionVertical } from './OptionVertical';
import { Paper } from './Paper';
import { PaperFill } from './PaperFill';
import { Pause } from './Pause';
import { Pen } from './Pen';
import { PenFill } from './PenFill';
import { PenPaper } from './PenPaper';
import { PenPaperFill } from './PenPaperFill';
import { Person } from './Person';
import { PersonFill } from './PersonFill';
import { PersonPlus } from './PersonPlus';
import { PersonPlusFill } from './PersonPlusFill';
import { PersonStack } from './PersonStack';
import { PersonStackFill } from './PersonStackFill';
import { Picture } from './Picture';
import { PictureFill } from './PictureFill';
import { Pin } from './Pin';
import { PinFill } from './PinFill';
import { PinSlash } from './PinSlash';
import { PinSlashFill } from './PinSlashFill';
import { Play } from './Play';
import { PlayFill } from './PlayFill';
import { Plus } from './Plus';
import { PlusSmall } from './PlusSmall';
import { PlusSmallCircle } from './PlusSmallCircle';
import { PlusSmallCircleFill } from './PlusSmallCircleFill';
import { QuestionmarkSmall } from './QuestionmarkSmall';
import { QuestionmarkSmallBubble } from './QuestionmarkSmallBubble';
import { QuestionmarkSmallBubbleColor } from './QuestionmarkSmallBubbleColor';
import { QuestionmarkSmallCircle } from './QuestionmarkSmallCircle';
import { QuestionmarkSmallCircleColor } from './QuestionmarkSmallCircleColor';
import { QuestionmarkSmallCircleFill } from './QuestionmarkSmallCircleFill';
import { RectangleDash } from './RectangleDash';
import { RectangleStack } from './RectangleStack';
import { RectangleStackFill } from './RectangleStackFill';
import { Refresh } from './Refresh';
import { RotateCircle } from './RotateCircle';
import { RotateClockwise } from './RotateClockwise';
import { RotateCounterclockwise } from './RotateCounterclockwise';
import { Send } from './Send';
import { SendFill } from './SendFill';
import { Share } from './Share';
import { Shuffle } from './Shuffle';
import { SlashCircle } from './SlashCircle';
import { SlashCircleFill } from './SlashCircleFill';
import { SparklingFill } from './SparklingFill';
import { SpeakerLv0 } from './SpeakerLv0';
import { SpeakerLv0Fill } from './SpeakerLv0Fill';
import { SpeakerLv1 } from './SpeakerLv1';
import { SpeakerLv1Fill } from './SpeakerLv1Fill';
import { SpeakerLv2 } from './SpeakerLv2';
import { SpeakerLv2Fill } from './SpeakerLv2Fill';
import { SpeakerLv2Slash } from './SpeakerLv2Slash';
import { SpeakerLv2SlashFill } from './SpeakerLv2SlashFill';
import { SquareCircleStack } from './SquareCircleStack';
import { SquareCircleStackFill } from './SquareCircleStackFill';
import { SquareGrid } from './SquareGrid';
import { SquareGridFill } from './SquareGridFill';
import { SquareGridPlus } from './SquareGridPlus';
import { SquareGridPlusFill } from './SquareGridPlusFill';
import { SquareStack } from './SquareStack';
import { SquareStackFill } from './SquareStackFill';
import { Star } from './Star';
import { StarFill } from './StarFill';
import { StarHalf } from './StarHalf';
import { Switch } from './Switch';
import { Teleport } from './Teleport';
import { Text } from './Text';
import { Trash } from './Trash';
import { TrashFill } from './TrashFill';
import { Trophy } from './Trophy';
import { TrophyFill } from './TrophyFill';
import { Tshirt } from './Tshirt';
import { TshirtFill } from './TshirtFill';
import { Underbar } from './Underbar';
import { Untitled } from './Untitled';
import { Upload } from './Upload';
import { Warninglight } from './Warninglight';
import { WarninglightFill } from './WarninglightFill';
import { Whiteboard } from './Whiteboard';
import { WhiteboardFill } from './WhiteboardFill';
import { Window } from './Window';
import { WindowDown } from './WindowDown';
import { WindowDownFill } from './WindowDownFill';
import { WindowFill } from './WindowFill';
import { WindowLeft } from './WindowLeft';
import { WindowLeftFill } from './WindowLeftFill';
import { WindowRight } from './WindowRight';
import { WindowRightFill } from './WindowRightFill';
import { WindowUp } from './WindowUp';
import { WindowUpFill } from './WindowUpFill';
import { X } from './X';
import { XSmall } from './XSmall';
import { XSmallCircle } from './XSmallCircle';
import { XSmallCircleColor } from './XSmallCircleColor';
import { XSmallCircleFill } from './XSmallCircleFill';
import { Youtube } from './Youtube';
import { YoutubeFill } from './YoutubeFill';
export const ActionColorIcon = ZepIcon(ActionColor);
export const AddSmallCircleColorIcon = ZepIcon(AddSmallCircleColor);
export const AddSmallRectangleFillIcon = ZepIcon(AddSmallRectangleFill);
export const AddSmallRectangleIcon = ZepIcon(AddSmallRectangle);
export const AddSmallSlashRectangleFillIcon = ZepIcon(
  AddSmallSlashRectangleFill,
);
export const AddSmallSlashRectangleIcon = ZepIcon(AddSmallSlashRectangle);
export const ArrowDownSmallCircleFillIcon = ZepIcon(ArrowDownSmallCircleFill);
export const ArrowDownIcon = ZepIcon(ArrowDown);
export const ArrowLeftSmallCircleFillIcon = ZepIcon(ArrowLeftSmallCircleFill);
export const ArrowLeftIcon = ZepIcon(ArrowLeft);
export const ArrowRightSmallCircleFillIcon = ZepIcon(ArrowRightSmallCircleFill);
export const ArrowRightIcon = ZepIcon(ArrowRight);
export const ArrowTurnDownleftIcon = ZepIcon(ArrowTurnDownleft);
export const ArrowTurnDownrightIcon = ZepIcon(ArrowTurnDownright);
export const ArrowTurnLeftdownIcon = ZepIcon(ArrowTurnLeftdown);
export const ArrowTurnLeftupIcon = ZepIcon(ArrowTurnLeftup);
export const ArrowTurnRightdownIcon = ZepIcon(ArrowTurnRightdown);
export const ArrowTurnRightupIcon = ZepIcon(ArrowTurnRightup);
export const ArrowTurnUpleftIcon = ZepIcon(ArrowTurnUpleft);
export const ArrowTurnUprightIcon = ZepIcon(ArrowTurnUpright);
export const ArrowUpSmallCircleFillIcon = ZepIcon(ArrowUpSmallCircleFill);
export const ArrowUpIcon = ZepIcon(ArrowUp);
export const ArrowsLeftrightSquareFillIcon = ZepIcon(ArrowsLeftrightSquareFill);
export const ArrowsLeftrightIcon = ZepIcon(ArrowsLeftright);
export const ArrowsUpdownIcon = ZepIcon(ArrowsUpdown);
export const ArrowsUpdownleftrightIcon = ZepIcon(ArrowsUpdownleftright);
export const Authority1ColorIcon = ZepIcon(Authority1Color);
export const Authority2ColorIcon = ZepIcon(Authority2Color);
export const Authority3ColorIcon = ZepIcon(Authority3Color);
export const BatteryFillIcon = ZepIcon(BatteryFill);
export const BellFillIcon = ZepIcon(BellFill);
export const BellSlashFillIcon = ZepIcon(BellSlashFill);
export const BellSlashIcon = ZepIcon(BellSlash);
export const BellIcon = ZepIcon(Bell);
export const BoltFillIcon = ZepIcon(BoltFill);
export const BoltIcon = ZepIcon(Bolt);
export const BookCoverFillIcon = ZepIcon(BookCoverFill);
export const BookFillIcon = ZepIcon(BookFill);
export const BookIcon = ZepIcon(Book);
export const BulbColorIcon = ZepIcon(BulbColor);
export const BulbIcon = ZepIcon(Bulb);
export const CalendarFillIcon = ZepIcon(CalendarFill);
export const CalendarIcon = ZepIcon(Calendar);
export const CameraFillIcon = ZepIcon(CameraFill);
export const CameraIcon = ZepIcon(Camera);
export const CaretBigDownIcon = ZepIcon(CaretBigDown);
export const CaretBigLeftIcon = ZepIcon(CaretBigLeft);
export const CaretBigRightIcon = ZepIcon(CaretBigRight);
export const CaretBigUpIcon = ZepIcon(CaretBigUp);
export const CaretBigUpdownIcon = ZepIcon(CaretBigUpdown);
export const CaretDownIcon = ZepIcon(CaretDown);
export const CaretLeftIcon = ZepIcon(CaretLeft);
export const CaretRightIcon = ZepIcon(CaretRight);
export const CaretUpIcon = ZepIcon(CaretUp);
export const CaretUpdownIcon = ZepIcon(CaretUpdown);
export const CartFillIcon = ZepIcon(CartFill);
export const CartIcon = ZepIcon(Cart);
export const ChangeIcon = ZepIcon(Change);
export const CheckFlowerFillIcon = ZepIcon(CheckFlowerFill);
export const CheckFlowerIcon = ZepIcon(CheckFlower);
export const CheckSmallCircleColorIcon = ZepIcon(CheckSmallCircleColor);
export const CheckSmallCircleFillIcon = ZepIcon(CheckSmallCircleFill);
export const CheckSmallCircleIcon = ZepIcon(CheckSmallCircle);
export const CheckSmallIcon = ZepIcon(CheckSmall);
export const CheckIcon = ZepIcon(Check);
export const ChevronDoubleDownIcon = ZepIcon(ChevronDoubleDown);
export const ChevronDoubleLeftIcon = ZepIcon(ChevronDoubleLeft);
export const ChevronDoubleRightIcon = ZepIcon(ChevronDoubleRight);
export const ChevronDoubleUpIcon = ZepIcon(ChevronDoubleUp);
export const ChevronDownIcon = ZepIcon(ChevronDown);
export const ChevronLeftIcon = ZepIcon(ChevronLeft);
export const ChevronRightIcon = ZepIcon(ChevronRight);
export const ChevronUpIcon = ZepIcon(ChevronUp);
export const ClipboardCheckFillIcon = ZepIcon(ClipboardCheckFill);
export const ClipboardCheckIcon = ZepIcon(ClipboardCheck);
export const ClipboardFillIcon = ZepIcon(ClipboardFill);
export const ClipboardIcon = ZepIcon(Clipboard);
export const ClockFillIcon = ZepIcon(ClockFill);
export const ClockIcon = ZepIcon(Clock);
export const CloudFillIcon = ZepIcon(CloudFill);
export const CloudSlashFillIcon = ZepIcon(CloudSlashFill);
export const CloudSlashIcon = ZepIcon(CloudSlash);
export const CloudIcon = ZepIcon(Cloud);
export const CodeQrIcon = ZepIcon(CodeQr);
export const CoinColorV2Icon = ZepIcon(CoinColorV2);
export const CoinStackFillIcon = ZepIcon(CoinStackFill);
export const CoinStackIcon = ZepIcon(CoinStack);
export const ContractIcon = ZepIcon(Contract);
export const CreditCardIcon = ZepIcon(CreditCard);
export const CrownFillIcon = ZepIcon(CrownFill);
export const CrownIcon = ZepIcon(Crown);
export const CurrencyDollarCircleFillIcon = ZepIcon(CurrencyDollarCircleFill);
export const CurrencyDollarCircleIcon = ZepIcon(CurrencyDollarCircle);
export const CurrencyWonCircleFillIcon = ZepIcon(CurrencyWonCircleFill);
export const CurrencyWonCircleIcon = ZepIcon(CurrencyWonCircle);
export const CurrencyYenCircleFillIcon = ZepIcon(CurrencyYenCircleFill);
export const CurrencyYenCircleIcon = ZepIcon(CurrencyYenCircle);
export const DiceFillIcon = ZepIcon(DiceFill);
export const DiceIcon = ZepIcon(Dice);
export const DownloadIcon = ZepIcon(Download);
export const EnterIcon = ZepIcon(Enter);
export const ExclamationmarkSmallCircleColorIcon = ZepIcon(
  ExclamationmarkSmallCircleColor,
);
export const ExclamationmarkSmallCircleFillIcon = ZepIcon(
  ExclamationmarkSmallCircleFill,
);
export const ExclamationmarkSmallCircleIcon = ZepIcon(
  ExclamationmarkSmallCircle,
);
export const ExclamationmarkSmallTriangleFillIcon = ZepIcon(
  ExclamationmarkSmallTriangleFill,
);
export const ExclamationmarkSmallTriangleIcon = ZepIcon(
  ExclamationmarkSmallTriangle,
);
export const ExclamationmarkSmallIcon = ZepIcon(ExclamationmarkSmall);
export const ExpandIcon = ZepIcon(Expand);
export const ExportIcon = ZepIcon(Export);
export const EyeFillIcon = ZepIcon(EyeFill);
export const EyeSlashFillIcon = ZepIcon(EyeSlashFill);
export const EyeSlashIcon = ZepIcon(EyeSlash);
export const EyeIcon = ZepIcon(Eye);
export const FaceFillIcon = ZepIcon(FaceFill);
export const FaceIcon = ZepIcon(Face);
export const FilterIcon = ZepIcon(Filter);
export const FolderCheckFillIcon = ZepIcon(FolderCheckFill);
export const FolderCheckIcon = ZepIcon(FolderCheck);
export const FolderFillIcon = ZepIcon(FolderFill);
export const FolderIcon = ZepIcon(Folder);
export const FullscreenIcon = ZepIcon(Fullscreen);
export const FunnelFillIcon = ZepIcon(FunnelFill);
export const FunnelIcon = ZepIcon(Funnel);
export const GameFillIcon = ZepIcon(GameFill);
export const GameIcon = ZepIcon(Game);
export const GearFillIcon = ZepIcon(GearFill);
export const GearIcon = ZepIcon(Gear);
export const GlobeFillIcon = ZepIcon(GlobeFill);
export const GlobeIcon = ZepIcon(Globe);
export const GoogleClassroomLogoIcon = ZepIcon(GoogleClassroomLogo);
export const GridNoneIcon = ZepIcon(GridNone);
export const HammerFillIcon = ZepIcon(HammerFill);
export const HammerIcon = ZepIcon(Hammer);
export const HeartFillIcon = ZepIcon(HeartFill);
export const HeartIcon = ZepIcon(Heart);
export const HouseFillIcon = ZepIcon(HouseFill);
export const HouseIcon = ZepIcon(House);
export const ImportIcon = ZepIcon(Import);
export const InformationmarkSmallCircleFillIcon = ZepIcon(
  InformationmarkSmallCircleFill,
);
export const InformationmarkSmallCircleIcon = ZepIcon(
  InformationmarkSmallCircle,
);
export const InformationmarkSmallIcon = ZepIcon(InformationmarkSmall);
export const InternetconnectionSlashIcon = ZepIcon(InternetconnectionSlash);
export const InternetconnectionIcon = ZepIcon(Internetconnection);
export const KeyboardDownColorIcon = ZepIcon(KeyboardDownColor);
export const KeyboardLeftColorIcon = ZepIcon(KeyboardLeftColor);
export const KeyboardRightColorIcon = ZepIcon(KeyboardRightColor);
export const KeyboardUpColorIcon = ZepIcon(KeyboardUpColor);
export const LeaveIcon = ZepIcon(Leave);
export const LinkDisconnectIcon = ZepIcon(LinkDisconnect);
export const LinkIcon = ZepIcon(Link);
export const ListIcon = ZepIcon(List);
export const LocationFillIcon = ZepIcon(LocationFill);
export const LocationIcon = ZepIcon(Location);
export const LockFillIcon = ZepIcon(LockFill);
export const LockIcon = ZepIcon(Lock);
export const LoginIcon = ZepIcon(Login);
export const LogoutIcon = ZepIcon(Logout);
export const LoudspeakerFillIcon = ZepIcon(LoudspeakerFill);
export const LoudspeakerIcon = ZepIcon(Loudspeaker);
export const MagnifingglassMinusIcon = ZepIcon(MagnifingglassMinus);
export const MagnifingglassPlusIcon = ZepIcon(MagnifingglassPlus);
export const MagnifingglassIcon = ZepIcon(Magnifingglass);
export const MailFillIcon = ZepIcon(MailFill);
export const MailIcon = ZepIcon(Mail);
export const MapFillIcon = ZepIcon(MapFill);
export const MapIcon = ZepIcon(Map);
export const MenuSmallIcon = ZepIcon(MenuSmall);
export const MenuIcon = ZepIcon(Menu);
export const MessageDotsFillIcon = ZepIcon(MessageDotsFill);
export const MessageDotsSlashFillIcon = ZepIcon(MessageDotsSlashFill);
export const MessageDotsSlashIcon = ZepIcon(MessageDotsSlash);
export const MessageDotsIcon = ZepIcon(MessageDots);
export const MessageLinesFillIcon = ZepIcon(MessageLinesFill);
export const MessageLinesIcon = ZepIcon(MessageLines);
export const MinimizescreenIcon = ZepIcon(Minimizescreen);
export const MinusSmallIcon = ZepIcon(MinusSmall);
export const MinusIcon = ZepIcon(Minus);
export const MobileFillIcon = ZepIcon(MobileFill);
export const MobileIcon = ZepIcon(Mobile);
export const MusicFillIcon = ZepIcon(MusicFill);
export const MusicIcon = ZepIcon(Music);
export const OptionHorizontalIcon = ZepIcon(OptionHorizontal);
export const OptionVerticalIcon = ZepIcon(OptionVertical);
export const PaperFillIcon = ZepIcon(PaperFill);
export const PaperIcon = ZepIcon(Paper);
export const PauseIcon = ZepIcon(Pause);
export const PenFillIcon = ZepIcon(PenFill);
export const PenPaperFillIcon = ZepIcon(PenPaperFill);
export const PenPaperIcon = ZepIcon(PenPaper);
export const PenIcon = ZepIcon(Pen);
export const PersonFillIcon = ZepIcon(PersonFill);
export const PersonPlusFillIcon = ZepIcon(PersonPlusFill);
export const PersonPlusIcon = ZepIcon(PersonPlus);
export const PersonStackFillIcon = ZepIcon(PersonStackFill);
export const PersonStackIcon = ZepIcon(PersonStack);
export const PersonIcon = ZepIcon(Person);
export const PictureFillIcon = ZepIcon(PictureFill);
export const PictureIcon = ZepIcon(Picture);
export const PinFillIcon = ZepIcon(PinFill);
export const PinSlashFillIcon = ZepIcon(PinSlashFill);
export const PinSlashIcon = ZepIcon(PinSlash);
export const PinIcon = ZepIcon(Pin);
export const PlayFillIcon = ZepIcon(PlayFill);
export const PlayIcon = ZepIcon(Play);
export const PlusSmallCircleFillIcon = ZepIcon(PlusSmallCircleFill);
export const PlusSmallCircleIcon = ZepIcon(PlusSmallCircle);
export const PlusSmallIcon = ZepIcon(PlusSmall);
export const PlusIcon = ZepIcon(Plus);
export const QuestionmarkSmallBubbleColorIcon = ZepIcon(
  QuestionmarkSmallBubbleColor,
);
export const QuestionmarkSmallBubbleIcon = ZepIcon(QuestionmarkSmallBubble);
export const QuestionmarkSmallCircleColorIcon = ZepIcon(
  QuestionmarkSmallCircleColor,
);
export const QuestionmarkSmallCircleFillIcon = ZepIcon(
  QuestionmarkSmallCircleFill,
);
export const QuestionmarkSmallCircleIcon = ZepIcon(QuestionmarkSmallCircle);
export const QuestionmarkSmallIcon = ZepIcon(QuestionmarkSmall);
export const RectangleStackFillIcon = ZepIcon(RectangleStackFill);
export const RectangleStackIcon = ZepIcon(RectangleStack);
export const RefreshIcon = ZepIcon(Refresh);
export const RotateCircleIcon = ZepIcon(RotateCircle);
export const RotateClockwiseIcon = ZepIcon(RotateClockwise);
export const RotateCounterclockwiseIcon = ZepIcon(RotateCounterclockwise);
export const SendFillIcon = ZepIcon(SendFill);
export const SendIcon = ZepIcon(Send);
export const ShareIcon = ZepIcon(Share);
export const ShuffleIcon = ZepIcon(Shuffle);
export const SlashCircleFillIcon = ZepIcon(SlashCircleFill);
export const SlashCircleIcon = ZepIcon(SlashCircle);
export const SparklingFillIcon = ZepIcon(SparklingFill);
export const SpeakerLv0FillIcon = ZepIcon(SpeakerLv0Fill);
export const SpeakerLv0Icon = ZepIcon(SpeakerLv0);
export const SpeakerLv1FillIcon = ZepIcon(SpeakerLv1Fill);
export const SpeakerLv1Icon = ZepIcon(SpeakerLv1);
export const SpeakerLv2FillIcon = ZepIcon(SpeakerLv2Fill);
export const SpeakerLv2SlashFillIcon = ZepIcon(SpeakerLv2SlashFill);
export const SpeakerLv2SlashIcon = ZepIcon(SpeakerLv2Slash);
export const SpeakerLv2Icon = ZepIcon(SpeakerLv2);
export const SquareGridFillIcon = ZepIcon(SquareGridFill);
export const SquareGridPlusFillIcon = ZepIcon(SquareGridPlusFill);
export const SquareGridPlusIcon = ZepIcon(SquareGridPlus);
export const SquareGridIcon = ZepIcon(SquareGrid);
export const SquareStackFillIcon = ZepIcon(SquareStackFill);
export const SquareStackIcon = ZepIcon(SquareStack);
export const SquareCircleStackFillIcon = ZepIcon(SquareCircleStackFill);
export const SquareCircleStackIcon = ZepIcon(SquareCircleStack);
export const StarFillIcon = ZepIcon(StarFill);
export const StarHalfIcon = ZepIcon(StarHalf);
export const StarIcon = ZepIcon(Star);
export const SwitchIcon = ZepIcon(Switch);
export const TeleportIcon = ZepIcon(Teleport);
export const TextIcon = ZepIcon(Text);
export const TrashFillIcon = ZepIcon(TrashFill);
export const TrashIcon = ZepIcon(Trash);
export const TrophyFillIcon = ZepIcon(TrophyFill);
export const TrophyIcon = ZepIcon(Trophy);
export const TshirtFillIcon = ZepIcon(TshirtFill);
export const TshirtIcon = ZepIcon(Tshirt);
export const UnderbarIcon = ZepIcon(Underbar);
export const UntitledIcon = ZepIcon(Untitled);
export const UploadIcon = ZepIcon(Upload);
export const WarninglightFillIcon = ZepIcon(WarninglightFill);
export const WarninglightIcon = ZepIcon(Warninglight);
export const WhiteboardFillIcon = ZepIcon(WhiteboardFill);
export const WhiteboardIcon = ZepIcon(Whiteboard);
export const WindowDownFillIcon = ZepIcon(WindowDownFill);
export const WindowDownIcon = ZepIcon(WindowDown);
export const WindowFillIcon = ZepIcon(WindowFill);
export const WindowLeftFillIcon = ZepIcon(WindowLeftFill);
export const WindowLeftIcon = ZepIcon(WindowLeft);
export const WindowRightFillIcon = ZepIcon(WindowRightFill);
export const WindowRightIcon = ZepIcon(WindowRight);
export const WindowUpFillIcon = ZepIcon(WindowUpFill);
export const WindowUpIcon = ZepIcon(WindowUp);
export const WindowIcon = ZepIcon(Window);
export const XSmallCircleColorIcon = ZepIcon(XSmallCircleColor);
export const XSmallCircleFillIcon = ZepIcon(XSmallCircleFill);
export const XSmallCircleIcon = ZepIcon(XSmallCircle);
export const XSmallIcon = ZepIcon(XSmall);
export const XIcon = ZepIcon(X);
export const YoutubeFillIcon = ZepIcon(YoutubeFill);
export const YoutubeIcon = ZepIcon(Youtube);
export const RectangleDashIcon = ZepIcon(RectangleDash);
