import { useMemo } from 'react';
import { useIsMounted } from '@toss/react';

import { googleClassroomContextManager } from '../GoogleClassroomContextManager';
import { UserType } from '../types';

export function useIsInGoogleClassroom() {
  const isMounted = useIsMounted();

  return useMemo(() => {
    if (!isMounted) {
      return false;
    }

    const context = googleClassroomContextManager.getLandingPageContext();
    if (context == null) {
      return false;
    }

    switch (context.userType) {
      case UserType.Student:
        return true;
      case UserType.Teacher:
        return true;
      case UserType.Unknown:
        return false;
    }
  }, [isMounted]);
}
