import { HTMLAttributeAnchorTarget, PropsWithChildren, ReactNode } from 'react';
import { SupportedLocale } from '@zep/consts/localize';
import { useUser } from '@zep/lib/auth';
import { Button } from '@zep/ui';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export type GnbButtonInfo = {
  link: string;
  text: string;
  track: string;
  alias: string;
  icon?: string | ReactNode;
  hideLocales: SupportedLocale[];
  target: HTMLAttributeAnchorTarget;
};

export const LeftButtons = ({
  gnbButtonInfos,
}: {
  gnbButtonInfos: Record<string, GnbButtonInfo>;
}) => {
  const {
    t,
    i18n: { resolvedLanguage = 'ko' },
  } = useTranslation();
  const { user } = useUser();

  return (
    <>
      {Object.values(gnbButtonInfos)
        .filter(
          l => !l.hideLocales.includes(resolvedLanguage as SupportedLocale),
        )
        .map(d => (
          <GnbLeftButton key={d.link}>
            <Link
              href={d.link.startsWith('common') ? t(d.link) : d.link}
              target={d.target}
              prefetch={false}
              onClick={() => {}}>
              {d.text}
            </Link>
          </GnbLeftButton>
        ))}
      {user?.admin && (
        <GnbLeftButton key={'admin'}>
          <Link href={'/admin'} target={'_blank'} prefetch={false}>
            어드민
          </Link>
        </GnbLeftButton>
      )}
    </>
  );
};

const GnbLeftButton = (props: PropsWithChildren) => (
  <Button size={'sm'} transParented intent={'neutral'} asChild>
    {props.children}
  </Button>
);
