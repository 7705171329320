// eslint-disable-next-line simple-import-sort/imports
import '../styles/globals.scss';
import '../init';

import {
  HydrationBoundary,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from '@tanstack/react-query';
import { useInitAnalytics, useRouteChangeTracker } from '../hooks';

import { AppProps } from 'next/app';
import { ChannelTalk } from '@zep/lib/channelTalk';
import GlobalErrorBoundary from '../components/GlobalErrorBoundary';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Meta } from '../components/Meta';
import { NextPageWithLayout } from 'next';
import NiceModal from '@ebay/nice-modal-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from 'react-hot-toast';
import { UserProvider } from '@zep/lib/auth';
import { appWithTranslation } from 'next-i18next';
import { getRootLayout } from '@zep/layout';
import { useApiError } from '../hooks/useApiError';
import { useState } from 'react';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import { SubscribeUserPlan } from '@zep/module/plan';
import { OverlayProvider } from 'overlay-kit';
import { Conditional } from '@zep/shared/googleClassroom';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const handleError = useApiError();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            throwOnError: false,
          },
          queries: {
            throwOnError: true,
            retry: false,
            staleTime: 1000 * 60 * 3,
          },
        },
        mutationCache: new MutationCache({
          onError: (error, _, __, mutation) => {
            if (mutation.meta?.ignoreError) {
              return;
            }
            handleError(error);
          },
        }),
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (query.meta?.ignoreError) {
              return;
            }
            handleError(error);
          },
        }),
      }),
  );

  useRouteChangeTracker();
  useInitAnalytics();

  const getLayout = Component.getLayout ?? getRootLayout;

  return (
    <OverlayProvider>
      <QueryErrorResetBoundary>
        <GlobalErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <NuqsAdapter>
              <UserProvider>
                <NiceModal.Provider>
                  <HydrationBoundary state={pageProps.dehydratedState}>
                    <Meta
                      partialSeoContent={pageProps.seoContent}
                      noIndex={pageProps.noIndex}
                    />
                    <SubscribeUserPlan />
                    <GoogleOAuthProvider
                      clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_ID || ''}>
                      <Conditional inGoogleClassroom={null}>
                        <ChannelTalk />
                      </Conditional>
                      <div id="root">
                        {getLayout(<Component {...pageProps} />)}
                      </div>
                    </GoogleOAuthProvider>
                    <Toaster position={'top-right'} />
                    <ReactQueryDevtools
                      buttonPosition={'bottom-left'}
                      initialIsOpen={false}
                    />
                  </HydrationBoundary>
                </NiceModal.Provider>
              </UserProvider>
            </NuqsAdapter>
          </QueryClientProvider>
        </GlobalErrorBoundary>
      </QueryErrorResetBoundary>
    </OverlayProvider>
  );
}

export default appWithTranslation(CustomApp);
