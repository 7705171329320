import { useMemo } from 'react';
import { NoSSR } from '@zep/components';
import { HeaderContainer } from '@zep/layout/Header/HeaderContainer';
import { MobileDrawer } from '@zep/layout/Header/MobileDrawer/MobileDrawer';
import {
  AuthButton,
  CreateNewQuizButton,
} from '@zep/layout/Header/UserManagementButton';
import { useTranslation } from 'next-i18next';

import { GnbButtonInfo, LeftButtons } from './LeftButtons';
import { ExploreButton } from './PageNavigatorButton';

export function GnbHeaderForGoogleClassroom() {
  const { t } = useTranslation();

  const gnbButtonInfo: Record<'pricing' | 'public', GnbButtonInfo> =
    useMemo(() => {
      return {
        pricing: {
          alias: '내 퀴즈',
          link: '/me/quiz',
          target: '_self',
          icon: '/assets/icon_magnifying_glass.svg',
          text: t(
            'googleClassroom.pricingTextgnbHeader.pricingText',
            '내 퀴즈',
          ),
          track: '',
          hideLocales: [],
        },
        public: {
          alias: '둘러보기',
          link: '/public',
          target: '_self',
          text: t('header.explore.button'),
          track: '',
          hideLocales: [],
        },
      };
    }, [t]);

  return (
    <HeaderContainer>
      <div className="flex items-center gap-[24px]">
        <div className={'gap hidden gap-sm md:flex'}>
          <LeftButtons gnbButtonInfos={gnbButtonInfo} />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <div className={'flex md:hidden'}>
          <ExploreButton.Icon />
        </div>
        <AuthButton />
        <div className={'hidden gap-2xs md:flex md:items-center'}>
          <CreateNewQuizButton type={'normal'} />
        </div>

        <NoSSR fallback={<MobileDrawer.TriggerContent />}>
          <MobileDrawer
            logo={<div />}
            // MobileDrawer에 내 퀴즈 버튼이 이미 존재하므로 gnbButtonInfo.pricing은 제외합니다.
            gnbButtonInfos={{ public: gnbButtonInfo.public }}
            footerBottomAccessory={<CreateNewQuizButton type={'drawer'} />}
          />
        </NoSSR>
      </div>
    </HeaderContainer>
  );
}
