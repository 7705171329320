import { ComponentPropsWithoutRef, forwardRef, LegacyRef } from 'react';

interface TriggerContentProps extends ComponentPropsWithoutRef<'img'> {}

export const TriggerContent = forwardRef<HTMLImageElement, TriggerContentProps>(
  (props: ComponentPropsWithoutRef<'img'>, ref) => {
    return (
      <div className="w-[40px] md:hidden">
        <img
          src="/assets/icon_menu.svg"
          className="cursor-pointer p-xs hover:rounded-[8px] hover:bg-[#3E3E470A] md:hidden"
          {...props}
          ref={ref as LegacyRef<HTMLImageElement>}
          alt="menuicon"
        />
      </div>
    );
  },
);
TriggerContent.displayName = 'TriggerContent';
