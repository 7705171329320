import type { SVGProps } from 'react';
import * as React from 'react';
export const GoogleClassroomLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 20 20"
    {...props}>
    <path fill="#FFC112" d="M20 0H0v20h20z" />
    <path fill="#21A465" d="M18.333 1.667H1.667v16.666h16.666z" />
    <path
      fill="#57BB8A"
      fillRule="evenodd"
      d="M15 9.167a.833.833 0 1 0-1.667 0 .833.833 0 0 0 1.667 0m-.833 1.25c-1.205 0-2.5.591-2.5 1.323v.76h5v-.76c0-.732-1.295-1.323-2.5-1.323m-7.5-1.25a.833.833 0 1 0-1.667 0 .833.833 0 0 0 1.667 0m-.834 1.25c-1.205 0-2.5.591-2.5 1.323v.76h5v-.76c0-.732-1.295-1.323-2.5-1.323"
      clipRule="evenodd"
    />
    <path
      fill="#F7F7F7"
      fillRule="evenodd"
      d="M10.001 9.167a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5m-3.263 2.36v.973h6.595v-.972c0-1.074-1.707-1.945-3.297-1.945s-3.298.87-3.298 1.945"
      clipRule="evenodd"
    />
    <path
      fill="#F1F1F1"
      fillRule="evenodd"
      d="M11.667 17.083h5v1.25h-5z"
      clipRule="evenodd"
    />
  </svg>
);
