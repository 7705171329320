import { PropsWithChildren, ReactElement } from 'react';
import { Conditional } from '@zep/shared/googleClassroom';

import { StickyBanner } from '../components/StickyBanner/';

import { GnbHeader } from './Header/GnbHeader';
import { GnbHeaderForGoogleClassroom } from './Header/GnbHeaderForGoogleClassroom';

const RootLayout = (props: PropsWithChildren) => {
  return (
    <>
      <Conditional inGoogleClassroom={null}>
        <StickyBanner />
      </Conditional>
      <Conditional inGoogleClassroom={<GnbHeaderForGoogleClassroom />}>
        <GnbHeader />
      </Conditional>
      {props.children}
    </>
  );
};

export function getRootLayout(page: ReactElement) {
  return <RootLayout>{page}</RootLayout>;
}

export function getLightLayout(page: ReactElement) {
  return (
    <>
      <Conditional inGoogleClassroom={<GnbHeaderForGoogleClassroom />}>
        <GnbHeader />
      </Conditional>
      {page}
    </>
  );
}
