import { useState } from 'react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { SupportedLocale } from '@zep/consts/localize';
import { useBodyAttribute } from '@zep/hooks';
import { HomeLogo } from '@zep/layout';
import { useUser } from '@zep/lib/auth';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@zep/ui';
import { cn } from '@zep/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { GnbButtonInfo } from '../LeftButtons';
import { MyQuizButton } from '../PageNavigatorButton';
import {
  EditNicknameMbButton,
  LogInButton,
  LogOutButton,
} from '../UserManagementButton';

import { DrawerButton } from './DrawerButton';
import { DrawerOverlay } from './DrawerOverlay';
import { TriggerContent } from './TriggerContent';

interface Props {
  logo?: React.ReactNode;
  gnbButtonInfos: Record<string, GnbButtonInfo>;
  footerBottomAccessory?: React.ReactNode;
}

export const MobileDrawer = ({
  logo = <HomeLogo location={'header'} />,
  gnbButtonInfos,
  footerBottomAccessory,
}: Props) => {
  const { user } = useUser();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const isStickBannerShow =
    useBodyAttribute('data-is-stick-banner-show') === 'true';

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      {isOpen && <DrawerOverlay onClick={() => setIsOpen(false)} />}
      <Drawer
        open={isOpen}
        onOpenChange={setIsOpen}
        direction="top"
        modal={false}
        snapPoints={[]}
        fadeFromIndex={0}>
        <DrawerTrigger asChild>
          <TriggerContent onClick={() => setIsOpen(true)} />
        </DrawerTrigger>
        <DrawerContent
          aria-describedby={undefined}
          className={cn(
            ' bg-white z-40 inset-x-0 top-[64px] flex h-fit rounded-none shadow-[0_12px_16px_4px_#1c1b1f1f] focus:outline-none md:hidden',
            `${!isStickBannerShow && 'top-0'}`,
          )}>
          <DrawerHeader className="flex items-center justify-between border-b-[1px_solid_#ededed] px-md py-sm">
            <VisuallyHidden.Root>
              <DrawerTitle />
            </VisuallyHidden.Root>
            {logo}
            <DrawerClose className="cursor-pointer rounded-[8px] p-[8px] hover:bg-[#3E3E47]/[.04]">
              <img
                src="/assets/icon_close_gray.svg"
                className="size-[24px]"
                alt="closeicon"
              />
            </DrawerClose>
          </DrawerHeader>
          <div className="flex flex-col gap-xs p-md">
            {Object.values(gnbButtonInfos).map(buttonInfo => (
              <DrawerButton
                key={buttonInfo.link}
                hide={buttonInfo.hideLocales.includes(
                  resolvedLanguage as SupportedLocale,
                )}
                onClick={() => {
                  router.push(buttonInfo.link);
                  toggleOpen();
                }}>
                {t(buttonInfo.text)}
              </DrawerButton>
            ))}
          </div>
          <HorizontalLine />
          <DrawerFooter className="mt-0 pt-0">
            {user ? (
              <div className="flex flex-col gap-sm">
                <div className="flex items-center justify-between py-[20px]">
                  <EditNicknameMbButton />
                  <LogOutButton isDrawerOpen={isOpen} />
                </div>
                <MyQuizButton.Drawer onClick={toggleOpen} />
              </div>
            ) : (
              <LogInButton type={'drawer'} />
            )}
            {footerBottomAccessory}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const HorizontalLine = () => {
  return <div className="my-sm h-4xs w-full bg-muted" />;
};

MobileDrawer.TriggerContent = TriggerContent;
