import { Button, ButtonProps } from '@zep/ui';

export const DrawerButton = ({
  hide,
  ...props
}: ButtonProps & { hide: boolean }) =>
  hide ? null : (
    <Button
      size={'lg'}
      intent={'neutral'}
      className={'flex justify-start px-[16px]'}
      transParented
      {...props}
    />
  );
