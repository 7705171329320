import { zepAnalytics } from '@zep/utils/analytics';

export const trackGnbAnalytics = (
  to: string,
  location: string,
  userName?: string,
) => {
  zepAnalytics.track(`enter_${to}`, {
    location,
    ...(to === 'create' && { name: userName }),
  });
};
