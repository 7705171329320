import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useBooleanState, usePreservedCallback } from '@toss/react';
import { setHttpClientToken } from '@zep/api/httpClient';
import { GoogleOAuthStorage } from '@zep/shared/googleClassroom';
import { User } from '@zep/types';
import { combinePathAndQuery } from '@zep/utils';
import { zepAnalytics } from '@zep/utils/analytics';
import { noop } from 'lodash-es';
import { useRouter } from 'next/router';

import {
  clearStorageUser,
  getStorageUser,
  setStorageUser,
} from './userStorage';

const defaultUserContext: UserContextType = {
  user: null,
  setUser: noop,
  updateUser: noop,
  logOut: noop,
  isInitialization: false,
  isAuthenticated: false,
};

export const UserContext = createContext<UserContextType>(defaultUserContext);

export const UserProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const [isInitialization, setInitialization] = useBooleanState(false);

  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const handleUser = (_user: User | null) => {
    if (_user) {
      setHttpClientToken(_user.token);
      // TODO: user를 localStorage에 저장하는 이유가 무엇일까?
      setStorageUser(_user);
      redirectSignupPage(_user);
      setCurrentUser({ ...currentUser, ..._user });
      zepAnalytics.setAmplitudeUserId(_user.username);
      return;
    }
    setCurrentUser(null);
  };

  const logOut = useCallback(
    async ({ redirectTo }: { redirectTo?: string } = {}) => {
      clearStorageUser();
      setCurrentUser(null);
      setHttpClientToken('');
      zepAnalytics.resetAmplitudeUserId();
      GoogleOAuthStorage.resetToken();

      if (redirectTo != null) {
        await router.push(redirectTo);
      }

      return Promise.resolve();
    },
    [router],
  );

  const isAuthenticated = useMemo<boolean>(() => {
    if (currentUser) return true;
    if (!isInitialization) return false;
    return !!getStorageUser();
  }, [isInitialization, currentUser]);

  const redirectSignupPage = usePreservedCallback((user: User | null) => {
    if (!user) return;
    if (
      router.pathname.startsWith('/login') ||
      router.pathname.startsWith('/sign-up') ||
      router.pathname.startsWith('/trouble')
    ) {
      return;
    }

    if (!user.registrationStatus) {
      router.push(combinePathAndQuery('/sign-up/type', router.query));
      return;
    }

    if (!user.type || user.type.toLowerCase() === 'unknown') {
      router.push(combinePathAndQuery('/sign-up/type', router.query));
      return;
    }
  });

  const updateUser = usePreservedCallback((_user: Partial<User>) => {
    if (!currentUser) return;
    const user = { ...currentUser, ..._user };
    setStorageUser(user);
    setCurrentUser(user);
  });

  useEffect(() => {
    const user = getStorageUser();

    if (!user) {
      setInitialization();
      return;
    }

    const isExpired = user.expiredAt && new Date(user.expiredAt) < new Date();

    if (isExpired) {
      logOut({ redirectTo: '/login' });
      setInitialization();
      return;
    }
    setCurrentUser(user);
    setInitialization();

    redirectSignupPage(user);
  }, [logOut, redirectSignupPage, router, setInitialization]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        isInitialization,
        user: currentUser,
        setUser: handleUser,
        updateUser,
        logOut,
      }}>
      {children}
    </UserContext.Provider>
  );
};

type UserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
  updateUser(user: Partial<User>): void;
  logOut: (props?: { redirectTo?: string }) => void;
  isInitialization: boolean;
  isAuthenticated: boolean;
};
