// Ref: https://developers.google.com/classroom/reference/rest/v1/courses.announcements.addOnAttachments#AddOnAttachment
export type AddOnAttachment = {
  courseId: string;
  /** @deprecated */
  postId: string;
  itemId: string;
  id: string;
  title: string;
  teacherViewUri: {
    uri: string;
  };
  studentViewUri: {
    uri: string;
  };
  studentWorkReviewUri: {
    uri: string;
  };
  dueDate: {
    year: number;
    month: number;
    day: number;
  };
  dueTime: {
    hours: number;
    minutes: number;
    seconds: number;
    nanos: number;
  };
  maxPoints: number;
  copyHistory: Array<{
    courseId: string;
    postId: string;
    itemId: string;
    attachmentId: string;
  }>;
};

// Ref: https://developers.google.com/classroom/reference/rest/v1/courses.courseWork.addOnAttachments.studentSubmissions
export type AddOnAttachmentStudentSubmission = {
  pointsEarned: number;
  postSubmissionState: SubmissionState;
  userId: string;
};

// Ref: https://developers.google.com/classroom/reference/rest/v1/AddOnContext
type AddOnContext = {
  courseId: string;
  postId: string;
  itemId: string;
  supportsStudentWork: boolean;
};

// Ref: https://developers.google.com/classroom/reference/rest/v1/AddOnContext#StudentContext
export type StudentAddOnContext = AddOnContext & {
  studentContext: {
    submissionId: string;
  };
};

// Ref: https://developers.google.com/classroom/reference/rest/v1/AddOnContext#teachercontext
export type TeacherAddOnContext = AddOnContext;

export type AttachmentMetadata = {
  courseId: string;
  itemId: string;
  addOnToken: string | null;
  attachmentId: string | null;
};

export enum SubmissionState {
  SUBMISSION_STATE_UNSPECIFIED = 'SUBMISSION_STATE_UNSPECIFIED',
  NEW = 'NEW',
  CREATED = 'CREATED',
  TURNED_IN = 'TURNED_IN',
  RETURNED = 'RETURNED',
  RECLAIMED_BY_STUDENT = 'RECLAIMED_BY_STUDENT',
}

// Enums for User Types and Landing Page Types
export enum UserType {
  Teacher = 'Teacher',
  Student = 'Student',
  Unknown = 'Unknown',
}

export enum LandingPageType {
  AttachmentSetup = 'AttachmentSetup',
  TeacherView = 'TeacherView',
  StudentView = 'StudentView',
  StudentWorkReview = 'StudentWorkReview',
  // 구글 클래스룸 외부에서 접근한 경우
  OutOfClassroom = 'OutOfClassroom',
}

interface AttachmentSetupParams {
  courseId: string;
  itemId: string;
  itemType: string;
  addOnToken: string;
}

interface TeacherViewParams {
  courseId: string;
  itemId: string;
  itemType: string;
  attachmentId: string;
}

interface StudentViewParams {
  courseId: string;
  itemId: string;
  itemType: string;
  attachmentId: string;
}

interface StudentWorkReviewParams {
  courseId: string;
  itemId: string;
  itemType: string;
  attachmentId: string;
  submissionId: string;
}

export type AttachmentSetupContext = {
  pageType: LandingPageType.AttachmentSetup;
  userType: UserType;
  params: AttachmentSetupParams;
};

export type TeacherViewContext = {
  pageType: LandingPageType.TeacherView;
  userType: UserType;
  params: TeacherViewParams;
};

export type StudentViewContext = {
  pageType: LandingPageType.StudentView;
  userType: UserType;
  params: StudentViewParams;
};

export type StudentWorkReviewContext = {
  pageType: LandingPageType.StudentWorkReview;
  userType: UserType;
  params: StudentWorkReviewParams;
};

export type OutOfClassroomContext = {
  pageType: LandingPageType.OutOfClassroom;
  userType: UserType;
  params: Record<string, never>;
};

export type GoogleClassroomPageContext =
  | AttachmentSetupContext
  | TeacherViewContext
  | StudentViewContext
  | StudentWorkReviewContext
  | OutOfClassroomContext;
