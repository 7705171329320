import { safeLocalStorage } from '@toss/storage';

const STORAGE_KEY = 'googleToken';

export const GoogleOAuthStorage = {
  getToken: () => {
    return safeLocalStorage.get(STORAGE_KEY);
  },
  setToken: (token: string) => {
    safeLocalStorage.set(STORAGE_KEY, token);
  },
  resetToken: () => {
    safeLocalStorage.remove(STORAGE_KEY);
  },
};
