import { useMemo } from 'react';
import { NoSSR } from '@zep/components';
import { HomeLogo } from '@zep/layout';
import { HeaderContainer } from '@zep/layout/Header/HeaderContainer';
import { MobileDrawer } from '@zep/layout/Header/MobileDrawer/MobileDrawer';
import {
  AuthButton,
  CreateNewQuizButton,
} from '@zep/layout/Header/UserManagementButton';
import { useTranslation } from 'next-i18next';

import { GnbButtonInfo, LeftButtons } from './LeftButtons';
import { EnterCodeButton, ExploreButton } from './PageNavigatorButton';

export function GnbHeader() {
  const { t } = useTranslation();

  const gnbButtonInfo: Record<
    'pricing' | 'public' | 'notice' | 'guide',
    GnbButtonInfo
  > = useMemo(() => {
    return {
      pricing: {
        alias: '프라이싱',
        link: '/pricing',
        target: '_self',
        icon: '/assets/icon_magnifying_glass.svg',
        text: t('common.header.pricing'),
        track: '',
        hideLocales: [],
      },
      public: {
        alias: '둘러보기',
        link: '/public',
        target: '_self',
        text: t('header.explore.button'),
        track: '',
        hideLocales: [],
      },
      guide: {
        alias: '가이드',
        link: t('common.header.guideLink'),
        target: '_blank',
        text: t('common.header.userGuide'),
        track: '',
        hideLocales: [],
      },
      notice: {
        alias: '공지사항',
        link: t('common.header.noticeLink'),
        target: '_blank',
        text: t('common.header.notice'),
        track: '',
        hideLocales: ['ja'],
      },
    };
  }, [t]);

  return (
    <HeaderContainer>
      <div className="flex items-center gap-[24px]">
        <HomeLogo location={'header'} />
        <div className={'gap hidden gap-sm md:flex'}>
          <LeftButtons gnbButtonInfos={gnbButtonInfo} />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <div className={'flex md:hidden'}>
          <ExploreButton.Icon />
        </div>
        <AuthButton />
        <div className={'hidden gap-2xs md:flex md:items-center'}>
          <EnterCodeButton type={'normal'} />
          <CreateNewQuizButton type={'normal'} />
        </div>

        <NoSSR fallback={<MobileDrawer.TriggerContent />}>
          <MobileDrawer
            gnbButtonInfos={gnbButtonInfo}
            footerBottomAccessory={
              <>
                <CreateNewQuizButton type={'drawer'} />
                <EnterCodeButton type={'drawer'} />
              </>
            }
          />
        </NoSSR>
      </div>
    </HeaderContainer>
  );
}
